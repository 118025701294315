/*!
Pure v0.5.0
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
https://github.com/yui/pure/blob/master/LICENSE.md
*/
/*csslint regex-selectors:false, known-properties:false, duplicate-properties:false*/

.pure-offset-1-24 {
    margin-left: 4.1667%;
    *margin-left: 4.1357%;
}

.pure-offset-1-12,
.pure-offset-2-24 {
    margin-left: 8.3333%;
    *margin-left: 8.3023%;
}

.pure-offset-1-8,
.pure-offset-3-24 {
    margin-left: 12.5000%;
    *margin-left: 12.4690%;
}

.pure-offset-1-6,
.pure-offset-4-24 {
    margin-left: 16.6667%;
    *margin-left: 16.6357%;
}

.pure-offset-1-5 {
    margin-left: 20%;
    *margin-left: 19.9690%;
}

.pure-offset-5-24 {
    margin-left: 20.8333%;
    *margin-left: 20.8023%;
}

.pure-offset-1-4,
.pure-offset-6-24 {
    margin-left: 25%;
    *margin-left: 24.9690%;
}

.pure-offset-7-24 {
    margin-left: 29.1667%;
    *margin-left: 29.1357%;
}

.pure-offset-1-3,
.pure-offset-8-24 {
    margin-left: 33.3333%;
    *margin-left: 33.3023%;
}

.pure-offset-3-8,
.pure-offset-9-24 {
    margin-left: 37.5000%;
    *margin-left: 37.4690%;
}

.pure-offset-2-5 {
    margin-left: 40%;
    *margin-left: 39.9690%;
}

.pure-offset-5-12,
.pure-offset-10-24 {
    margin-left: 41.6667%;
    *margin-left: 41.6357%;
}

.pure-offset-11-24 {
    margin-left: 45.8333%;
    *margin-left: 45.8023%;
}

.pure-offset-1-2,
.pure-offset-12-24 {
    margin-left: 50%;
    *margin-left: 49.9690%;
}

.pure-offset-13-24 {
    margin-left: 54.1667%;
    *margin-left: 54.1357%;
}

.pure-offset-7-12,
.pure-offset-14-24 {
    margin-left: 58.3333%;
    *margin-left: 58.3023%;
}

.pure-offset-3-5 {
    margin-left: 60%;
    *margin-left: 59.9690%;
}

.pure-offset-5-8,
.pure-offset-15-24 {
    margin-left: 62.5000%;
    *margin-left: 62.4690%;
}

.pure-offset-2-3,
.pure-offset-16-24 {
    margin-left: 66.6667%;
    *margin-left: 66.6357%;
}

.pure-offset-17-24 {
    margin-left: 70.8333%;
    *margin-left: 70.8023%;
}

.pure-offset-3-4,
.pure-offset-18-24 {
    margin-left: 75%;
    *margin-left: 74.9690%;
}

.pure-offset-19-24 {
    margin-left: 79.1667%;
    *margin-left: 79.1357%;
}

.pure-offset-4-5 {
    margin-left: 80%;
    *margin-left: 79.9690%;
}

.pure-offset-5-6,
.pure-offset-20-24 {
    margin-left: 83.3333%;
    *margin-left: 83.3023%;
}

.pure-offset-7-8,
.pure-offset-21-24 {
    margin-left: 87.5000%;
    *margin-left: 87.4690%;
}

.pure-offset-11-12,
.pure-offset-22-24 {
    margin-left: 91.6667%;
    *margin-left: 91.6357%;
}

.pure-offset-23-24 {
    margin-left: 95.8333%;
    *margin-left: 95.8023%;
}

.pure-offset-1,
.pure-offset-1-1,
.pure-offset-5-5,
.pure-offset-24-24 {
    margin-left: 100%;
}