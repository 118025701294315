/*!
Pure v0.5.0
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
https://github.com/yui/pure/blob/master/LICENSE.md
*/
@media screen and (min-width: 35.5em) {

    .pure-offset-sm-1-24 {
        margin-left: 4.1667%;
        *margin-left: 4.1357%;
    }

    .pure-offset-sm-1-12,
    .pure-offset-sm-2-24 {
        margin-left: 8.3333%;
        *margin-left: 8.3023%;
    }

    .pure-offset-sm-1-8,
    .pure-offset-sm-3-24 {
        margin-left: 12.5000%;
        *margin-left: 12.4690%;
    }

    .pure-offset-sm-1-6,
    .pure-offset-sm-4-24 {
        margin-left: 16.6667%;
        *margin-left: 16.6357%;
    }

    .pure-offset-sm-1-5 {
        margin-left: 20%;
        *margin-left: 19.9690%;
    }

    .pure-offset-sm-5-24 {
        margin-left: 20.8333%;
        *margin-left: 20.8023%;
    }

    .pure-offset-sm-1-4,
    .pure-offset-sm-6-24 {
        margin-left: 25%;
        *margin-left: 24.9690%;
    }

    .pure-offset-sm-7-24 {
        margin-left: 29.1667%;
        *margin-left: 29.1357%;
    }

    .pure-offset-sm-1-3,
    .pure-offset-sm-8-24 {
        margin-left: 33.3333%;
        *margin-left: 33.3023%;
    }

    .pure-offset-sm-3-8,
    .pure-offset-sm-9-24 {
        margin-left: 37.5000%;
        *margin-left: 37.4690%;
    }

    .pure-offset-sm-2-5 {
        margin-left: 40%;
        *margin-left: 39.9690%;
    }

    .pure-offset-sm-5-12,
    .pure-offset-sm-10-24 {
        margin-left: 41.6667%;
        *margin-left: 41.6357%;
    }

    .pure-offset-sm-11-24 {
        margin-left: 45.8333%;
        *margin-left: 45.8023%;
    }

    .pure-offset-sm-1-2,
    .pure-offset-sm-12-24 {
        margin-left: 50%;
        *margin-left: 49.9690%;
    }

    .pure-offset-sm-13-24 {
        margin-left: 54.1667%;
        *margin-left: 54.1357%;
    }

    .pure-offset-sm-7-12,
    .pure-offset-sm-14-24 {
        margin-left: 58.3333%;
        *margin-left: 58.3023%;
    }

    .pure-offset-sm-3-5 {
        margin-left: 60%;
        *margin-left: 59.9690%;
    }

    .pure-offset-sm-5-8,
    .pure-offset-sm-15-24 {
        margin-left: 62.5000%;
        *margin-left: 62.4690%;
    }

    .pure-offset-sm-2-3,
    .pure-offset-sm-16-24 {
        margin-left: 66.6667%;
        *margin-left: 66.6357%;
    }

    .pure-offset-sm-17-24 {
        margin-left: 70.8333%;
        *margin-left: 70.8023%;
    }

    .pure-offset-sm-3-4,
    .pure-offset-sm-18-24 {
        margin-left: 75%;
        *margin-left: 74.9690%;
    }

    .pure-offset-sm-19-24 {
        margin-left: 79.1667%;
        *margin-left: 79.1357%;
    }

    .pure-offset-sm-4-5 {
        margin-left: 80%;
        *margin-left: 79.9690%;
    }

    .pure-offset-sm-5-6,
    .pure-offset-sm-20-24 {
        margin-left: 83.3333%;
        *margin-left: 83.3023%;
    }

    .pure-offset-sm-7-8,
    .pure-offset-sm-21-24 {
        margin-left: 87.5000%;
        *margin-left: 87.4690%;
    }

    .pure-offset-sm-11-12,
    .pure-offset-sm-22-24 {
        margin-left: 91.6667%;
        *margin-left: 91.6357%;
    }

    .pure-offset-sm-23-24 {
        margin-left: 95.8333%;
        *margin-left: 95.8023%;
    }

    .pure-offset-sm-1,
    .pure-offset-sm-1-1,
    .pure-offset-sm-5-5,
    .pure-offset-sm-24-24 {
        margin-left: 100%;
    }
}

@media screen and (min-width: 48em) {
    .pure-offset-md-1,
    .pure-offset-md-1-1,
    .pure-offset-md-1-2,
    .pure-offset-md-1-3,
    .pure-offset-md-2-3,
    .pure-offset-md-1-4,
    .pure-offset-md-3-4,
    .pure-offset-md-1-5,
    .pure-offset-md-2-5,
    .pure-offset-md-3-5,
    .pure-offset-md-4-5,
    .pure-offset-md-5-5,
    .pure-offset-md-1-6,
    .pure-offset-md-5-6,
    .pure-offset-md-1-8,
    .pure-offset-md-3-8,
    .pure-offset-md-5-8,
    .pure-offset-md-7-8,
    .pure-offset-md-1-12,
    .pure-offset-md-5-12,
    .pure-offset-md-7-12,
    .pure-offset-md-11-12,
    .pure-offset-md-1-24,
    .pure-offset-md-2-24,
    .pure-offset-md-3-24,
    .pure-offset-md-4-24,
    .pure-offset-md-5-24,
    .pure-offset-md-6-24,
    .pure-offset-md-7-24,
    .pure-offset-md-8-24,
    .pure-offset-md-9-24,
    .pure-offset-md-10-24,
    .pure-offset-md-11-24,
    .pure-offset-md-12-24,
    .pure-offset-md-13-24,
    .pure-offset-md-14-24,
    .pure-offset-md-15-24,
    .pure-offset-md-16-24,
    .pure-offset-md-17-24,
    .pure-offset-md-18-24,
    .pure-offset-md-19-24,
    .pure-offset-md-20-24,
    .pure-offset-md-21-24,
    .pure-offset-md-22-24,
    .pure-offset-md-23-24,
    .pure-offset-md-24-24 {
        display: inline-block;
        *display: inline;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        vertical-align: top;
        text-rendering: auto;
    }

    .pure-offset-md-1-24 {
        margin-left: 4.1667%;
        *margin-left: 4.1357%;
    }

    .pure-offset-md-1-12,
    .pure-offset-md-2-24 {
        margin-left: 8.3333%;
        *margin-left: 8.3023%;
    }

    .pure-offset-md-1-8,
    .pure-offset-md-3-24 {
        margin-left: 12.5000%;
        *margin-left: 12.4690%;
    }

    .pure-offset-md-1-6,
    .pure-offset-md-4-24 {
        margin-left: 16.6667%;
        *margin-left: 16.6357%;
    }

    .pure-offset-md-1-5 {
        margin-left: 20%;
        *margin-left: 19.9690%;
    }

    .pure-offset-md-5-24 {
        margin-left: 20.8333%;
        *margin-left: 20.8023%;
    }

    .pure-offset-md-1-4,
    .pure-offset-md-6-24 {
        margin-left: 25%;
        *margin-left: 24.9690%;
    }

    .pure-offset-md-7-24 {
        margin-left: 29.1667%;
        *margin-left: 29.1357%;
    }

    .pure-offset-md-1-3,
    .pure-offset-md-8-24 {
        margin-left: 33.3333%;
        *margin-left: 33.3023%;
    }

    .pure-offset-md-3-8,
    .pure-offset-md-9-24 {
        margin-left: 37.5000%;
        *margin-left: 37.4690%;
    }

    .pure-offset-md-2-5 {
        margin-left: 40%;
        *margin-left: 39.9690%;
    }

    .pure-offset-md-5-12,
    .pure-offset-md-10-24 {
        margin-left: 41.6667%;
        *margin-left: 41.6357%;
    }

    .pure-offset-md-11-24 {
        margin-left: 45.8333%;
        *margin-left: 45.8023%;
    }

    .pure-offset-md-1-2,
    .pure-offset-md-12-24 {
        margin-left: 50%;
        *margin-left: 49.9690%;
    }

    .pure-offset-md-13-24 {
        margin-left: 54.1667%;
        *margin-left: 54.1357%;
    }

    .pure-offset-md-7-12,
    .pure-offset-md-14-24 {
        margin-left: 58.3333%;
        *margin-left: 58.3023%;
    }

    .pure-offset-md-3-5 {
        margin-left: 60%;
        *margin-left: 59.9690%;
    }

    .pure-offset-md-5-8,
    .pure-offset-md-15-24 {
        margin-left: 62.5000%;
        *margin-left: 62.4690%;
    }

    .pure-offset-md-2-3,
    .pure-offset-md-16-24 {
        margin-left: 66.6667%;
        *margin-left: 66.6357%;
    }

    .pure-offset-md-17-24 {
        margin-left: 70.8333%;
        *margin-left: 70.8023%;
    }

    .pure-offset-md-3-4,
    .pure-offset-md-18-24 {
        margin-left: 75%;
        *margin-left: 74.9690%;
    }

    .pure-offset-md-19-24 {
        margin-left: 79.1667%;
        *margin-left: 79.1357%;
    }

    .pure-offset-md-4-5 {
        margin-left: 80%;
        *margin-left: 79.9690%;
    }

    .pure-offset-md-5-6,
    .pure-offset-md-20-24 {
        margin-left: 83.3333%;
        *margin-left: 83.3023%;
    }

    .pure-offset-md-7-8,
    .pure-offset-md-21-24 {
        margin-left: 87.5000%;
        *margin-left: 87.4690%;
    }

    .pure-offset-md-11-12,
    .pure-offset-md-22-24 {
        margin-left: 91.6667%;
        *margin-left: 91.6357%;
    }

    .pure-offset-md-23-24 {
        margin-left: 95.8333%;
        *margin-left: 95.8023%;
    }

    .pure-offset-md-1,
    .pure-offset-md-1-1,
    .pure-offset-md-5-5,
    .pure-offset-md-24-24 {
        margin-left: 100%;
    }
}

@media screen and (min-width: 64em) {
    .pure-offset-lg-1,
    .pure-offset-lg-1-1,
    .pure-offset-lg-1-2,
    .pure-offset-lg-1-3,
    .pure-offset-lg-2-3,
    .pure-offset-lg-1-4,
    .pure-offset-lg-3-4,
    .pure-offset-lg-1-5,
    .pure-offset-lg-2-5,
    .pure-offset-lg-3-5,
    .pure-offset-lg-4-5,
    .pure-offset-lg-5-5,
    .pure-offset-lg-1-6,
    .pure-offset-lg-5-6,
    .pure-offset-lg-1-8,
    .pure-offset-lg-3-8,
    .pure-offset-lg-5-8,
    .pure-offset-lg-7-8,
    .pure-offset-lg-1-12,
    .pure-offset-lg-5-12,
    .pure-offset-lg-7-12,
    .pure-offset-lg-11-12,
    .pure-offset-lg-1-24,
    .pure-offset-lg-2-24,
    .pure-offset-lg-3-24,
    .pure-offset-lg-4-24,
    .pure-offset-lg-5-24,
    .pure-offset-lg-6-24,
    .pure-offset-lg-7-24,
    .pure-offset-lg-8-24,
    .pure-offset-lg-9-24,
    .pure-offset-lg-10-24,
    .pure-offset-lg-11-24,
    .pure-offset-lg-12-24,
    .pure-offset-lg-13-24,
    .pure-offset-lg-14-24,
    .pure-offset-lg-15-24,
    .pure-offset-lg-16-24,
    .pure-offset-lg-17-24,
    .pure-offset-lg-18-24,
    .pure-offset-lg-19-24,
    .pure-offset-lg-20-24,
    .pure-offset-lg-21-24,
    .pure-offset-lg-22-24,
    .pure-offset-lg-23-24,
    .pure-offset-lg-24-24 {
        display: inline-block;
        *display: inline;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        vertical-align: top;
        text-rendering: auto;
    }

    .pure-offset-lg-1-24 {
        margin-left: 4.1667%;
        *margin-left: 4.1357%;
    }

    .pure-offset-lg-1-12,
    .pure-offset-lg-2-24 {
        margin-left: 8.3333%;
        *margin-left: 8.3023%;
    }

    .pure-offset-lg-1-8,
    .pure-offset-lg-3-24 {
        margin-left: 12.5000%;
        *margin-left: 12.4690%;
    }

    .pure-offset-lg-1-6,
    .pure-offset-lg-4-24 {
        margin-left: 16.6667%;
        *margin-left: 16.6357%;
    }

    .pure-offset-lg-1-5 {
        margin-left: 20%;
        *margin-left: 19.9690%;
    }

    .pure-offset-lg-5-24 {
        margin-left: 20.8333%;
        *margin-left: 20.8023%;
    }

    .pure-offset-lg-1-4,
    .pure-offset-lg-6-24 {
        margin-left: 25%;
        *margin-left: 24.9690%;
    }

    .pure-offset-lg-7-24 {
        margin-left: 29.1667%;
        *margin-left: 29.1357%;
    }

    .pure-offset-lg-1-3,
    .pure-offset-lg-8-24 {
        margin-left: 33.3333%;
        *margin-left: 33.3023%;
    }

    .pure-offset-lg-3-8,
    .pure-offset-lg-9-24 {
        margin-left: 37.5000%;
        *margin-left: 37.4690%;
    }

    .pure-offset-lg-2-5 {
        margin-left: 40%;
        *margin-left: 39.9690%;
    }

    .pure-offset-lg-5-12,
    .pure-offset-lg-10-24 {
        margin-left: 41.6667%;
        *margin-left: 41.6357%;
    }

    .pure-offset-lg-11-24 {
        margin-left: 45.8333%;
        *margin-left: 45.8023%;
    }

    .pure-offset-lg-1-2,
    .pure-offset-lg-12-24 {
        margin-left: 50%;
        *margin-left: 49.9690%;
    }

    .pure-offset-lg-13-24 {
        margin-left: 54.1667%;
        *margin-left: 54.1357%;
    }

    .pure-offset-lg-7-12,
    .pure-offset-lg-14-24 {
        margin-left: 58.3333%;
        *margin-left: 58.3023%;
    }

    .pure-offset-lg-3-5 {
        margin-left: 60%;
        *margin-left: 59.9690%;
    }

    .pure-offset-lg-5-8,
    .pure-offset-lg-15-24 {
        margin-left: 62.5000%;
        *margin-left: 62.4690%;
    }

    .pure-offset-lg-2-3,
    .pure-offset-lg-16-24 {
        margin-left: 66.6667%;
        *margin-left: 66.6357%;
    }

    .pure-offset-lg-17-24 {
        margin-left: 70.8333%;
        *margin-left: 70.8023%;
    }

    .pure-offset-lg-3-4,
    .pure-offset-lg-18-24 {
        margin-left: 75%;
        *margin-left: 74.9690%;
    }

    .pure-offset-lg-19-24 {
        margin-left: 79.1667%;
        *margin-left: 79.1357%;
    }

    .pure-offset-lg-4-5 {
        margin-left: 80%;
        *margin-left: 79.9690%;
    }

    .pure-offset-lg-5-6,
    .pure-offset-lg-20-24 {
        margin-left: 83.3333%;
        *margin-left: 83.3023%;
    }

    .pure-offset-lg-7-8,
    .pure-offset-lg-21-24 {
        margin-left: 87.5000%;
        *margin-left: 87.4690%;
    }

    .pure-offset-lg-11-12,
    .pure-offset-lg-22-24 {
        margin-left: 91.6667%;
        *margin-left: 91.6357%;
    }

    .pure-offset-lg-23-24 {
        margin-left: 95.8333%;
        *margin-left: 95.8023%;
    }

    .pure-offset-lg-1,
    .pure-offset-lg-1-1,
    .pure-offset-lg-5-5,
    .pure-offset-lg-24-24 {
        margin-left: 100%;
    }
}

@media screen and (min-width: 80em) {
    .pure-offset-xl-1,
    .pure-offset-xl-1-1,
    .pure-offset-xl-1-2,
    .pure-offset-xl-1-3,
    .pure-offset-xl-2-3,
    .pure-offset-xl-1-4,
    .pure-offset-xl-3-4,
    .pure-offset-xl-1-5,
    .pure-offset-xl-2-5,
    .pure-offset-xl-3-5,
    .pure-offset-xl-4-5,
    .pure-offset-xl-5-5,
    .pure-offset-xl-1-6,
    .pure-offset-xl-5-6,
    .pure-offset-xl-1-8,
    .pure-offset-xl-3-8,
    .pure-offset-xl-5-8,
    .pure-offset-xl-7-8,
    .pure-offset-xl-1-12,
    .pure-offset-xl-5-12,
    .pure-offset-xl-7-12,
    .pure-offset-xl-11-12,
    .pure-offset-xl-1-24,
    .pure-offset-xl-2-24,
    .pure-offset-xl-3-24,
    .pure-offset-xl-4-24,
    .pure-offset-xl-5-24,
    .pure-offset-xl-6-24,
    .pure-offset-xl-7-24,
    .pure-offset-xl-8-24,
    .pure-offset-xl-9-24,
    .pure-offset-xl-10-24,
    .pure-offset-xl-11-24,
    .pure-offset-xl-12-24,
    .pure-offset-xl-13-24,
    .pure-offset-xl-14-24,
    .pure-offset-xl-15-24,
    .pure-offset-xl-16-24,
    .pure-offset-xl-17-24,
    .pure-offset-xl-18-24,
    .pure-offset-xl-19-24,
    .pure-offset-xl-20-24,
    .pure-offset-xl-21-24,
    .pure-offset-xl-22-24,
    .pure-offset-xl-23-24,
    .pure-offset-xl-24-24 {
        display: inline-block;
        *display: inline;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        vertical-align: top;
        text-rendering: auto;
    }

    .pure-offset-xl-1-24 {
        margin-left: 4.1667%;
        *margin-left: 4.1357%;
    }

    .pure-offset-xl-1-12,
    .pure-offset-xl-2-24 {
        margin-left: 8.3333%;
        *margin-left: 8.3023%;
    }

    .pure-offset-xl-1-8,
    .pure-offset-xl-3-24 {
        margin-left: 12.5000%;
        *margin-left: 12.4690%;
    }

    .pure-offset-xl-1-6,
    .pure-offset-xl-4-24 {
        margin-left: 16.6667%;
        *margin-left: 16.6357%;
    }

    .pure-offset-xl-1-5 {
        margin-left: 20%;
        *margin-left: 19.9690%;
    }

    .pure-offset-xl-5-24 {
        margin-left: 20.8333%;
        *margin-left: 20.8023%;
    }

    .pure-offset-xl-1-4,
    .pure-offset-xl-6-24 {
        margin-left: 25%;
        *margin-left: 24.9690%;
    }

    .pure-offset-xl-7-24 {
        margin-left: 29.1667%;
        *margin-left: 29.1357%;
    }

    .pure-offset-xl-1-3,
    .pure-offset-xl-8-24 {
        margin-left: 33.3333%;
        *margin-left: 33.3023%;
    }

    .pure-offset-xl-3-8,
    .pure-offset-xl-9-24 {
        margin-left: 37.5000%;
        *margin-left: 37.4690%;
    }

    .pure-offset-xl-2-5 {
        margin-left: 40%;
        *margin-left: 39.9690%;
    }

    .pure-offset-xl-5-12,
    .pure-offset-xl-10-24 {
        margin-left: 41.6667%;
        *margin-left: 41.6357%;
    }

    .pure-offset-xl-11-24 {
        margin-left: 45.8333%;
        *margin-left: 45.8023%;
    }

    .pure-offset-xl-1-2,
    .pure-offset-xl-12-24 {
        margin-left: 50%;
        *margin-left: 49.9690%;
    }

    .pure-offset-xl-13-24 {
        margin-left: 54.1667%;
        *margin-left: 54.1357%;
    }

    .pure-offset-xl-7-12,
    .pure-offset-xl-14-24 {
        margin-left: 58.3333%;
        *margin-left: 58.3023%;
    }

    .pure-offset-xl-3-5 {
        margin-left: 60%;
        *margin-left: 59.9690%;
    }

    .pure-offset-xl-5-8,
    .pure-offset-xl-15-24 {
        margin-left: 62.5000%;
        *margin-left: 62.4690%;
    }

    .pure-offset-xl-2-3,
    .pure-offset-xl-16-24 {
        margin-left: 66.6667%;
        *margin-left: 66.6357%;
    }

    .pure-offset-xl-17-24 {
        margin-left: 70.8333%;
        *margin-left: 70.8023%;
    }

    .pure-offset-xl-3-4,
    .pure-offset-xl-18-24 {
        margin-left: 75%;
        *margin-left: 74.9690%;
    }

    .pure-offset-xl-19-24 {
        margin-left: 79.1667%;
        *margin-left: 79.1357%;
    }

    .pure-offset-xl-4-5 {
        margin-left: 80%;
        *margin-left: 79.9690%;
    }

    .pure-offset-xl-5-6,
    .pure-offset-xl-20-24 {
        margin-left: 83.3333%;
        *margin-left: 83.3023%;
    }

    .pure-offset-xl-7-8,
    .pure-offset-xl-21-24 {
        margin-left: 87.5000%;
        *margin-left: 87.4690%;
    }

    .pure-offset-xl-11-12,
    .pure-offset-xl-22-24 {
        margin-left: 91.6667%;
        *margin-left: 91.6357%;
    }

    .pure-offset-xl-23-24 {
        margin-left: 95.8333%;
        *margin-left: 95.8023%;
    }

    .pure-offset-xl-1,
    .pure-offset-xl-1-1,
    .pure-offset-xl-5-5,
    .pure-offset-xl-24-24 {
        margin-left: 100%;
    }
}